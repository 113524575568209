// import * as React from "react";

import "normalize.css";
import "@fontsource/oswald";
import "@fontsource/rubik";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-medium-image-zoom/dist/styles.css";

import "./src/styles/global.css";

// import Layout from "./src/components/Layout";

const transitionDelay = 300;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }
  return false;
};

// export const wrapPageElement = ({ element, props }) => {
//   // return <Layout>{element}</Layout>;
//   const Layout = element.type.Layout ?? React.Fragment;
//   return <Layout {...props}>{element}</Layout>;
// }
