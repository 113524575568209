import { defineStyle, defineStyleConfig, extendTheme } from "@chakra-ui/react";

const outline = defineStyle({
  // border: "20px dashed",
  color: "red",
  borderRadius: 0,
  // fontWeight: "semibold",
});

const buttonTheme = defineStyleConfig({
  variants: { outline },
});

const theme = {
  colors: {
    primary: "rebeccapurple",
  },
  components: {
    Button: buttonTheme,
  },
};

export default extendTheme(theme);
